.uploader-wrapper {
    .image-input {
        .image-input-wrapper {
            position: relative;

            .uploader-loading {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;

                z-index: 50;
            }
        }
    }
}