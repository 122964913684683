@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import './components/buttons.scss';
@import './components/table.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
    --scrollbarBG: #0f0f0f;
    --thumbBG: #15db95;
    scroll-behavior: smooth;
    background-color: white;
    font-family: 'Roboto';
}

.thumbnail {
    width: 125px;

    img {
        width: 100%;
    }
}

.spin-rotate {
    animation: spin 400ms infinite;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}