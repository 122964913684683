button:disabled {
    opacity: .5;
    cursor: not-allowed;
}

.button {
    @apply flex items-center focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center;
    &.button-primary {
        @apply bg-blue-700 hover:bg-blue-800 text-white;
    }

    &.button-danger {
        @apply bg-red-700 hover:bg-red-800 text-white;
    }

    &.button-small {
        @apply px-3 py-2 text-xs;
    }
}